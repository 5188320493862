<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHAPEL MANAGER INCENTIVES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        <span class="headline font-weight-light">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_chapel_manager_incetives()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Chapel Manager Name</th>
              <th class="text-center text-uppercase">Total Production</th>
              <th class="text-center text-uppercase">Total Callers</th>
              <th class="text-center text-uppercase">Net Production</th>
              <th class="text-center text-uppercase">Production Incentive Rate %</th>
              <th class="text-center text-uppercase">Chapel Manager Incentives</th>
              <th class="text-center text-uppercase">Commission</th>
              <th class="text-center text-uppercase">Kaagapay Inct.</th>
              <th class="text-center text-uppercase">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in chapel_manager_incentives_items" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.chapel_manager }}
              </td>
              <td class="text-center">
                {{ item.total_gross_production }}
              </td>
              <td class="text-center">
                {{ item.total_callers }}
              </td>
              <td class="text-center">
                {{ item.total_net_production }}
              </td>
              <td class="text-center">
                {{ item.rate }}
              </td>
              <td class="text-center">
                {{ item.total_cm_incentives }}
              </td>
              <td class="text-center">
                {{ item.total_commission }}
              </td>
              <td class="text-center">
                {{ item.kaagapay_incentives }}
              </td>
              <td class="text-center">
                {{ item.total_incentives }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      month_of: '',
      month_of_items: [],

      chapel_manager_incentives_items: [],
      is_deleting: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
        },
      }
    },
    created() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('chapel_manager_incentives', ['get_clients_for_cm_incentives_present_new']),
      monthly_remittances() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        data.append('is_start_new_month', 0)
        this.get_clients_for_cm_incentives_present_new(data)
          .then(response => {
            this.chapel_manager_incentives_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_chapel_manager_incetives() {
        var imgData = this.company_logo
        var month = this.month_of

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'CHAPEL MANAGER INCENTIVES', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'BRANCH: ' + this.branch,},
            ' ',
            {text: 'MONTH OF: ' + month},
            {text: 'CHAPEL MANAGER: ' + this.chapel_manager_incentives_items[0].chapel_manager},
            ' ',
            {
              text: 'TOTAL GROSS PRODUCTION: ' + this.chapel_manager_incentives_items[0].total_gross_production,
              style: {color: 'blue'}
            },
            {
              text: 'TOTAL CALLERS: ' + this.chapel_manager_incentives_items[0].total_callers,
              style: {color: 'red'}
            },
            {text: 'TOTAL NET PRODUCTION: ' + this.chapel_manager_incentives_items[0].total_net_production},
            {text: 'PRODUCTION INCENTIVE RATE: ' + this.chapel_manager_incentives_items[0].rate},
            {
              text: 'PRODUCTION INCENTIVE: ' + this.chapel_manager_incentives_items[0].total_cm_incentives,
              style: {color: 'blue'}
            },
            ' ',
            // {
            //   text: 'PREVIOUS SERVICE NET COLLECTION: ' + this.chapel_manager_incentives_items[0].total_other_payments,
            //   style: {color: 'blue'}
            // },
            // {text: 'INCENTIVE RATE: ' + this.payments_incentive_rate},
            // {
            //     text: 'PREVIOUS SERVICE PAYMENT INCENTIVE: ' + this.payments_incentives,
            //     style: {color: 'green'}
            // },
            ' ',
            // {
            //   text: 'ASSISTANCE PAYMENT: ' + this.chapel_manager_incentives_items[0].assistance_total_other_payments,
            //   style: {color: 'blue'}
            // },
            // {text: 'FOR REFUND PAYMENT: ' + this.chapel_manager_incentives_items[0].for_refund_payments, style: {color: 'red'}},
            // {
            //     text: 'TOTAL ASSISTANCE PAYMENT: ' + this.assistance_grand_total_other_payments
            // },
            // {text: 'ASSISTANCE PAYMENT INCENTIVE RATE: ' + this.assistance_payments_incentive_rate},
            // {
            //     text: 'ASSISTANCE PAYMENT INCENTIVE: ' + this.assistance_payments_incentives,
            //     style: {color: 'green'}
            // },
            {text: 'TOTAL KAAGAPAY SALES: ' + this.chapel_manager_incentives_items[0].kaagapay_sales},
            ' ',
            {
              text: 'CM INCENTIVES: ' + this.chapel_manager_incentives_items[0].total_cm_incentives,
              style: {color: 'blue'}
            },
            {
              text: 'COMMISSION: ' + this.chapel_manager_incentives_items[0].total_commission,
              style: {color: 'blue'}
            },
            {
              text: 'KAAGAPAY INCENTIVES: ' + this.chapel_manager_incentives_items[0].kaagapay_incentives,
              style: {color: 'blue'}
            },
            {
              text: 'TOTAL INCENTIVES: ' + this.chapel_manager_incentives_items[0].total_incentives,
              style: {color: 'green'}
            },
            ' ',
            'DEDUCTIONS',
            ' ',
            '__________________________________',
            ' ',
            '__________________________________',
            ' ',
            '__________________________________',
            ' ',
            '__________________________________',
            ' ',
            'NET INCOME',
            ' ',
            '__________________________________',
          ],
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },

          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
